import React from 'react';
import { Field, Form } from '@availity/form';
import * as yup from 'yup';
import { Button, Col, Row, Spinner, UncontrolledCollapse } from 'reactstrap';
import { SelectField } from '@availity/select';
import { useDestination, useDestinations } from '@/hooks';
import { uuid } from 'uuidv4';
import useProfile from '@/areas/Profile/useProfile';
import { FaDownload, FaFlask } from 'react-icons/fa';
import { useApolloClient } from 'react-apollo';
import { destinationAttributeByIdQuery } from '@/graphql/queries';
import { LoadingButton } from '@/components';
import KeyValueArrayField from './KeyValueArrayField';

const methodTypes = [
  {
    label: 'POST',
    value: 'POST',
  },
  {
    label: 'GET',
    value: 'GET',
  },
  {
    label: 'PUT',
    value: 'PUT',
  },
];

const contentTypes = [
  {
    label: 'JSON',
    value: 'application/json',
  },
  {
    label: 'XML',
    value: 'application/xml',
  },
  {
    label: 'TEXT',
    value: 'text/plain',
  },
  {
    label: 'PARAMS',
    value: 'params',
  },
  {
    label: 'FORM URL',
    value: 'application/x-www-form-urlencoded',
  },
];

interface CustomAPIProfileProps {
  destinationID?: string;
}

const CustomAPIProfile: React.FC<CustomAPIProfileProps> = ({
  destinationID,
}) => {
  const {
    data: { agentID },
  } = useProfile();
  const { destinations } = useDestinations(agentID);
  const {
    loading,
    update,
    destination,
    updating,
    test: testDestination,
    testing: testingDestination,
  } = useDestination(agentID, destinationID);
  const apolloClient = useApolloClient();

  const downloadAttributes = async () => {
    const { data } = await apolloClient.query({
      query: destinationAttributeByIdQuery,
      variables: {
        id: '9142dd41-a120-42dd-8f59-8263e7910a14',
      },
    });
    const rows = data.destinationAttributeById.attributes.reduce(
      (arr: string[][], nextAttr: any) => {
        arr.push([nextAttr.field, nextAttr.mapTo]);
        return arr;
      },
      [['Field', 'Mapped To']]
    );
    const csvContent = `data:text/csv;charset=utf-8,${rows
      .map((e: string[]) => e.join(','))
      .join('\n')}`;
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'destination_mapping.csv');
    document.body.appendChild(link);
    link.click();
  };

  if (loading) {
    return (
      <div>
        <Spinner color="primary" />
      </div>
    );
  }

  return (
    <Form
      validationSchema={yup.object().shape({
        name: yup
          .string()
          .required('This field is required.')
          .test(
            'duplicate',
            'Destination name already exists.',
            function testDuplicate(name: string) {
              return !destinations
                .filter(
                  (n) =>
                    n.destinationType === 'api' && n.destID !== destinationID
                )
                .map((n) => n.destinationName)
                .includes(name);
            }
          ),
      })}
      initialValues={{
        headers: destination?.headers || [],
        params: destination?.params || [],
        body: destination?.body || [],
        name: destination?.destinationName || '',
        url: destination?.destination || '',
        method: destination?.method || 'POST',
        contentType: destination?.contentType || '',
      }}
      // eslint-disable-next-line no-void
      onSubmit={(values) =>
        update({
          variables: {
            input: {
              destination: {
                destinationType: 'api',
                status: 'active',
                destination: values.url,
                destID: destinationID === 'new' ? uuid() : destinationID,
                destinationName: values.name,
                body: values.body,
                headers: values.headers,
                params: values.params,
                method: values.method,
                contentType: values.contentType,
              },
            },
          },
        })
      }
    >
      <Row>
        <Col xs={12}>
          <Field name="name" label="Name" placeholder="Enter a Name..." />
        </Col>
        <Col xs={12}>
          <Field
            name="url"
            label="URL"
            placeholder="https://mycustomapi.com/crm/contacts"
          />
        </Col>
        <Col xs={12}>
          <Button color="link" id="toggler" type="button" className="mb-3">
            Advanced Options
          </Button>
        </Col>
        <UncontrolledCollapse
          toggler="#toggler"
          tag={Row}
          className="w-100 ml-0"
        >
          <Col xs={6}>
            <SelectField
              name="method"
              label="Method"
              placeholder="Method"
              options={methodTypes}
            />
          </Col>
          <Col xs={6}>
            <SelectField
              name="contentType"
              label="Content Type"
              isClearable
              placeholder="Content Type"
              options={contentTypes}
            />
          </Col>
          <Col xs={12}>
            <KeyValueArrayField name="headers" label="Headers" />
          </Col>
          <Col xs={12}>
            <KeyValueArrayField name="params" label="Query Params" />
          </Col>
          <Col xs={12}>
            <KeyValueArrayField name="body" label="Body" />
          </Col>
        </UncontrolledCollapse>
      </Row>
      <Button type="button" color="warning" onClick={downloadAttributes}>
        Download Mapping <FaDownload />
      </Button>
      <LoadingButton
        color="info"
        type="button"
        className="ml-2"
        onClick={() => testDestination()}
        disabled={destinationID === 'new'}
        loading={testingDestination}
      >
        Test <FaFlask />
      </LoadingButton>
      <Button
        color="primary"
        className="float-right"
        disabled={updating}
        type="submit"
      >
        {destinationID === 'new' ? 'Create' : 'Save Changes'}
      </Button>
      <Button color="secondary" className="float-right mr-1" type="reset">
        {destinationID === 'new' ? 'Reset' : 'Reset Changes'}
      </Button>
    </Form>
  );
};

export default CustomAPIProfile;
