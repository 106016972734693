import { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { useQuery, useMutation } from '@apollo/react-hooks';
import currentTermsAndConditionsVersionQuery from '@/graphql/queries/currentTermsAndConditionsVersion';
import acceptTermsAndConditions from '@/graphql/mutations/acceptTermsAndConditions';
import React from 'react';
import { useNotifications, useUser } from '@/hooks';
import { getTermsAndConditions } from '@/utils/documents';
import { NotificationActionsEnum } from '@/context/NotificationContext';

const TermsAcceptanceModal: React.FC = () => {
  const { user } = useUser();
  const [isOpen, setIsOpen] = useState(false);
  const { data: termsData } = useQuery(currentTermsAndConditionsVersionQuery);
  const [acceptTerms, { loading: accepting }] = useMutation(
    acceptTermsAndConditions
  );
  const { create: createNotification } = useNotifications();
  const currentVersion = termsData?.currentTermsAndConditionsVersion?.version;

  useEffect(() => {
    if (user && currentVersion) {
      setIsOpen(user?.termsAndConditionsAcceptedVersion !== currentVersion);
    }
  }, [user, currentVersion]);

  const handleAccept = async () => {
    try {
      await acceptTerms({ variables: { version: currentVersion } });
      createNotification({
        message: 'Successfully accepted',
        type: 'alert',
        action: NotificationActionsEnum.message,
        color: 'success',
        timeout: 1000,
      });
      setIsOpen(false);
    } catch (error) {
      createNotification({
        message: `Error accepting terms: ${error?.message}`,
        color: 'danger',
        type: 'alert',
        action: NotificationActionsEnum.message,
        timeout: 3000,
      });
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => {}}
      backdrop="static"
      keyboard={false}
      className="terms-acceptance-modal"
    >
      <ModalHeader toggle={() => {}}>Updated Terms and Conditions</ModalHeader>
      <ModalBody>
        <div className="prose mb-6">
          We've made updates to our Terms of Service.
          <p className="mb-4">
            Please review the
            <a
              href={getTermsAndConditions(user.corpID)}
              rel="noopener noreferrer"
              target="_blank"
              className="text-primary hover:underline"
            >
              {' '}
              updated terms
            </a>{' '}
            to continue using the platform.
          </p>
          <p className="text-sm text-muted-foreground">
            You'll need to accept the new terms before proceeding.
          </p>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={handleAccept} disabled={accepting}>
          I Accept
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default TermsAcceptanceModal;
